import { useEffect, useRef, useState } from "react";
import {
    useColorModeValue,
    Container,
    Box,
    Button,
    FormControl,
    Input,
    FormLabel,
    Text,
    Img,
    IconButton,
    VStack,
    Alert,
    AlertIcon,
    CircularProgress,
    Grid,
    GridItem,
    Textarea,
} from "@chakra-ui/react"
import Resizer from "react-image-file-resizer";
import { Link, useParams } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { getAuthHeader } from "../../authUtil";

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

interface Image {
    guid: string,
    url: string,
    blobString: string
}

const LotView = () => {
    let { eventId }: any = useParams();
    const imgRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState<Image[]>([]);

    const username = localStorage.getItem('auctionworx-username');
    const password = localStorage.getItem('auctionworx-password');

    const [eventTitle, setEventTitle] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>();

    const [listingTitle, setListingTitle] = useState<string>('');
    const [listingDescription, setListingDescription] = useState<string>('');
    const [listingPrice, setListingPrice] = useState<number>(100);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCameraCapture = async (file: Blob) => {
        // TODO: potential bug with same index if the upload has not been completed before next one...
        const resizedImage = await resizeFile(file, 2000, 2000); // 2000*2000px
        const result = await uploadImage(resizedImage, uploadedImages.length + 1);

        if (result.success) {
            const image: Image = {
                guid: result.guid!,
                url: result.url!,
                blobString: URL.createObjectURL(file)
            }
            setUploadedImages(uploadedImages => [...uploadedImages, image])
        } else {
            setErrorMessage('Failed to upload image.');
        }
    }

    const resizeFile = (file: Blob, maxWidth: number, maxHeight: number) => new Promise<File>(resolve => {
        const jpegQuality: number = 90;
        const compressFormat = file.type.match('image/jp.*') ? "JPEG" : "PNG";

        Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, jpegQuality, 0,
            b => { resolve(b as File); }, 'blob', maxWidth, maxHeight
        );
    })

    const uploadImage = async (file: File, index: number) => {
        // Docs for Auctionworx backend: http://www.rainworx.com/Dev-Docs/Default.htm#sending_image_data.htm%3FTocPath%3DWeb%2520API%2520in%2520AuctionWorx%7CCreating%2520an%2520Android%2520Client%2520for%2520Web%2520API%7C_____4
        // Copied from https://dev.to/jbrocher/react-tips-tricks-uploading-a-file-with-a-progress-bar-3m5p

        let formData = new FormData();
        formData.append('upload', file, `${eventId}_pic${index}.jpg`);
        formData.append('context', 'UploadListingImage');

        const apiEndpoint = `${API_BASE_URL}/api/Media/`;
        const resp = await axios.post(apiEndpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getAuthHeader()
            },
            onUploadProgress: (progressEvent: any) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 50;
                setUploadProgress(progress);
            },
            onDownloadProgress: (progressEvent: any) => {
                const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                setUploadProgress(progress);
            },
        });

        if (resp.status !== 201) {
            return {
                success: false,
                errorCode: resp.status,
                errorMessage: resp.statusText
            }
        }

        const result = resp.data[0];
        const guid: string = result.GUID;
        const url: string = result.Variations.FullSize.Asset.MetaData.PhysicalURI;

        return {
            success: true,
            guid: guid,
            url: url
        }
    }

    const createNew = () => {
        setUploadedImages([]);
        setListingTitle('');
        setListingDescription('');
        setListingPrice(0);
        setIsCompleted(false);
    }

    const save = async () => {
        if (isUploading) return;

        setIsSaving(true);
        
        if (await createListing()) {
            setIsCompleted(true);
        } else {
            setErrorMessage('Failed to create listing.');
            setIsUploading(false);
        }

        setIsSaving(false);
    }

    const removeImage = async (guid: string) => {
        setUploadedImages(uploadedImages.filter(x => x.guid !== guid));
        alert('Foto borttaget!');
    }

    const createListing = async () => {
        var headers = new Headers();
        headers.append("Authorization", `RWX_BASIC ${username}:${password}`);
        headers.append("Content-Type", "application/json");

        const langCode = 'en-US';
        let jsonObj: any = {
            "CultureUIName": langCode,
            "CultureName": langCode,
            "FBOUserName": username,
            "ActingUserName": username,
            "Items": {
                "LotNumber": "",
                "EventID": eventId,
                "ListingType": "Auction",
                "AllCategories": "50,9,160877,290492", // TODO: choose categories
                "CategoryID": "290492",
                "StoreID": "",
                "RegionID": "",
                "SaveAsDraft": "true",
                "Currency": "SEK",
                "Title": listingTitle,
                "Subtitle": "",
                "Description": listingDescription,
                "Price": listingPrice,
                "ReservePrice": "",
                "FixedPrice": "",
                "photo": "",
                "myFile": "",
                "Amount": "",
                "AdditionalItemAmount": "",
                "ShippingMethod": "164443"
            }
        };

        uploadedImages.forEach((img, index) => {
            // GUID field for the image to bind it to the listing
            const propName = `media_guid_${img.guid}`;
            jsonObj.Items[propName] = img.guid;

            // Sort field for the image
            const orderPropName = `media_ordr_${img.guid}`;
            jsonObj.Items[orderPropName] = index;
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(jsonObj)
        };

        const apiResp = await fetch(`${API_BASE_URL}/api/Listing/`, requestOptions);
        if (apiResp.status === 201) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        const titleInSessionStorage = sessionStorage
            .getItem('current-eventtitle')?.toString();
        if (titleInSessionStorage) {
            setEventTitle(titleInSessionStorage);
        }
    }, [eventId]);

    const shadow = useColorModeValue('sm', 'sm-dark');

    return (<Box as="section" py={{ base: '4', md: '8' }}>

        <Container maxW="3xl">
            {isUploading && !isCompleted && <Box
                bg="bg-surface"
                boxShadow={shadow}
                borderRadius="lg"
                p={{ base: '4', md: '12' }}
            >
                <VStack spacing={8}>
                    <Box>
                        <CircularProgress value={uploadProgress} size='120px' />
                    </Box>
                </VStack>
            </Box>}

            {!isCompleted && <Box
                bg="bg-surface"
                boxShadow={shadow}
                borderRadius="lg"
                p={{ base: '4', md: '6' }}
            >
                <Input ref={imgRef} type="file" hidden={true} aria-hidden="true"
                    accept="image/*;capture=camera" capture="camera"
                    onChange={(e: any) => onCameraCapture(e.target.files[0])} />

                {errorMessage && <Alert status='error' mb={2}>
                    <AlertIcon /> {errorMessage}
                </Alert>}

                <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                    {uploadedImages && uploadedImages.map((img: Image) => {
                        return (
                            <GridItem key={img.guid} w='100%'>
                                <IconButton
                                    style={{ position: 'absolute', top: 10, right: 10 }}
                                    variant='outline'
                                    aria-label='Remove'
                                    icon={<DeleteIcon />}
                                    onClick={() => removeImage(img.guid)}
                                />
                                <Img src={img.blobString} />
                            </GridItem>
                        )
                    })}
                </Grid>

                <Button mt={uploadedImages.length > 0 ? 4 : 0}
                    onClick={() => { (imgRef!.current! as any).click() }}>
                    Ta{(uploadedImages.length > 0 ? ' ytterligare' : '')} foto med kamera</Button>

                {uploadedImages && uploadedImages.length > 0 && <FormControl mt={4} isRequired={true}>
                    <FormLabel htmlFor="large" size="lg">Titel</FormLabel>
                    <Input size="lg" placeholder=" " data-peer
                        defaultValue={listingTitle}
                        onChange={(ev: any) => { setListingTitle(ev.target.value) }} />
                </FormControl>}

                {uploadedImages && uploadedImages.length > 0 && <FormControl mt={4} isRequired={true}>
                    <FormLabel htmlFor="large" size="lg">Beskrivning</FormLabel>
                    <Textarea size="sm" placeholder=" " data-peer
                        defaultValue={listingDescription}
                        onChange={(ev: any) => { setListingDescription(ev.target.value) }} />
                </FormControl>}

                {uploadedImages && uploadedImages.length > 0 && <FormControl mt={4}>
                    <Button isLoading={isSaving} isFullWidth={true} colorScheme='green'
                        isDisabled={listingTitle.length < 3} onClick={save}>
                        Skapa utrop
                    </Button>
                </FormControl>}

            </Box>}

            {isCompleted && <Box
                bg="bg-surface"
                boxShadow={shadow}
                borderRadius="lg"
                p={{ base: '4', md: '12' }}
            >
                <VStack spacing={8}>
                    <Box>
                        <Alert status='success'>
                            <AlertIcon />
                            Uppladdat och klart!
                        </Alert>
                    </Box>
                    <Box>
                        <Text fontSize='sm'>'{listingTitle}' har skapats på '{eventTitle}' 
                            med {uploadedImages.length} bilder.</Text>
                    </Box>
                    <Box>
                        <Button onClick={createNew}>
                            Skapa ytterligare utrop
                        </Button>
                    </Box>
                </VStack>
            </Box>}

        </Container>

        <VStack mt={2}>
            <Text fontSize='xs' mt={4}>Auktion: {eventTitle}</Text>
            <Link to="/">
                <Button size={'xs'} mt={2}>Byt auktionstillfälle</Button>
            </Link>
        </VStack>
    </Box>)
}

export default LotView;