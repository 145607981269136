export const getAuthHeader = () => {
    // Docs for AuctionWorx: http://www.rainworx.com/Dev-Docs/Default.htm#security_in_web_api.htm%3FTocPath%3DWeb%2520API%2520in%2520AuctionWorx%7C_____3
    const apiToken = localStorage.getItem('auctionworx-apitoken');
    if (apiToken) {
        return `RWX_BASIC ${apiToken}`;
    } else {
        const username = localStorage.getItem('auctionworx-username');
        const password = localStorage.getItem('auctionworx-password');
        return `RWX_BASIC ${username}:${password}`;
    }
};