import { useState } from "react";
import {
    useColorModeValue,
    Container,
    Box,
    Button,
    FormControl,
    Input,
    FormLabel,
} from "@chakra-ui/react";

const LoginView = () => {
    let authType = 'password';
    const [enteredApiToken, setEnteredApiToken] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const save = () => {
        if (authType === 'apitoken') {
            localStorage.setItem('auctionworx-apitoken', enteredApiToken);
        } else if (authType === 'password') {
            localStorage.setItem('auctionworx-username', username);
            localStorage.setItem('auctionworx-password', password);
        }
        window.location.reload();
    }

    return (<Box as="section" py={{ base: '4', md: '8' }}>
        <Container maxW="3xl">
            <Box mb={6}>
                <img src="https://kvibergs.se/static/images/kvibergs-logo2.svg" alt="Kvibergs" />
            </Box>
            <Box
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                p={{ base: '4', md: '6' }}
            >
                <form>
                    {authType === 'apitoken' && <FormControl mt={4} isRequired={true}>
                        <FormLabel htmlFor="apitoken" size="lg">
                            Ange API-nyckel
                        </FormLabel>
                        <Input id="apitoken" size="lg" defaultValue={enteredApiToken} 
                            onChange={(ev) => { setEnteredApiToken(ev.target.value) }} />
                    </FormControl>}

                    {authType === 'password' && <>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel htmlFor="username" size="md">
                                Användarnamn
                            </FormLabel>
                            <Input id="username" size="md" defaultValue={username} 
                                onChange={(ev) => { setUsername(ev.target.value) }} />
                        </FormControl>
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel htmlFor="password" size="md">
                                Lösenord
                            </FormLabel>
                            <Input id="password" type={'password'} size="md" defaultValue={password} 
                                onChange={(ev) => { setPassword(ev.target.value) }}
                                autoComplete={'current-password'} />
                        </FormControl>
                    </>}

                    <FormControl mt={4}>
                        <Button colorScheme='brand' onClick={save}>Logga in</Button>
                    </FormControl>
                </form>
            </Box>
        </Container>
    </Box>)
}

export default LoginView;