import {
  ChakraProvider,
  extendTheme,
  Spinner,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { theme } from '@chakra-ui/pro-theme'
import { Suspense } from "react"
import { Route, Switch } from "react-router-dom"

import LoginView from "./Components/LoginView/LoginView"
import EventList from "./Components/EventList/EventList"
import LotView from "./Components/LotView/LotView"

const customTheme = extendTheme({
    colors: {
        ...theme.colors,
        brand: {
          500: '#607a5c'
        }
    },
  },
  theme,
)

export const App = () => {
  const isAuthorized = localStorage.getItem('auctionworx-username');

  return (
    <ChakraProvider theme={customTheme}>
      <Suspense fallback={<Spinner as="progress" />}>

        {!isAuthorized && <LoginView />}

        {isAuthorized && <Switch>
          <Route exact path="/">
            <EventList />
          </Route>
          <Route path="/:eventId">
            <LotView />
          </Route>
        </Switch>}

        <ColorModeSwitcher />

      </Suspense>
    </ChakraProvider>
  )
}
