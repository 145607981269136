import { useEffect, useState } from "react";
import {
    Text,
    Container,
    Box,
    useColorModeValue,
    Stack,
    HStack,
    Square,
    Button,
    Center
} from "@chakra-ui/react"
import { getAuthHeader } from "../../authUtil";

const EventList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [auctionEvents, setAuctionEvents] = useState<any[]>();
    const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

    const getListFromBackend = async () => {
        setIsLoading(true);

        let headers = new Headers();
        headers.append('Authorization', getAuthHeader());

        const requestOptions = {
            method: 'GET',
            headers: headers
        };

        const apiEndpoint = `${API_BASE_URL}/app-api/events`;
        const apiRequest = await fetch(apiEndpoint, requestOptions);

        if (apiRequest.status === 401) {
            logout();
            window.location.reload();
        } else {
            const list = await apiRequest.json();
            setAuctionEvents(list);
            setIsLoading(false);
        }
    }

    const open = (eventId: string, eventTitle: string) => {
        sessionStorage.setItem('current-eventtitle', eventTitle);
        window.location.href = '/' + eventId; // TODO: manage in react
    }

    const logout = () => {
        console.warn('logging out');
        localStorage.removeItem('auctionworx-username');
        localStorage.removeItem('auctionworx-password');
    }

    useEffect(() => {
        getListFromBackend();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<Box as="section" py={{ base: '4', md: '8' }}>
        <Container maxW="3xl">
            <Box
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                p={{ base: '4', md: '6' }}
            >
                {!isLoading && <Stack spacing="5">
                    <Stack spacing="1">
                        <Text fontSize="lg" fontWeight="medium">
                            Aktiva auktioner
                        </Text>
                        <Text fontSize="sm" color="muted">
                            Klicka på den du vill lägga upp utropsbilder på.
                        </Text>
                    </Stack>
                    {auctionEvents && auctionEvents.length > 0 && auctionEvents.map((ev) => {
                        return (<Box key={ev.EventId} borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg">
                            <Stack justify="space-between" direction={{ base: 'column', md: 'row' }} spacing="5">
                                <HStack spacing="3">
                                    <Square size="10" bg="bg-subtle" borderRadius="lg"></Square>
                                    <Box fontSize="sm">
                                        <Text color="empahsized" fontWeight="medium">{ev.Title}</Text>
                                        <Text color="muted">{ev.Status}</Text>
                                    </Box>
                                </HStack>
                                <Stack spacing="3" 
                                    direction={{ base: 'column-reverse', md: 'row' }} 
                                    onClick={() => open(ev.EventId, ev.Title)}>
                                    <Button variant="primary">Öppna</Button>
                                </Stack>
                            </Stack>
                        </Box>)})}
                    {auctionEvents && auctionEvents.length === 0 && <>
                        <Text fontSize="sm" color="red">
                            Inloggad användare har inte några auktionstillfällen.
                        </Text>
                    </>}
                </Stack>}
            </Box>
        </Container>

        <Center>
            <Button size={'xs'} mt={4} onClick={logout}>Logga ut</Button>
        </Center>
    </Box>)
}

export default EventList;